import Beef from './Beef.png';
import BottleCanBeer from './Bottle&CanBeer.png';
import Butter from './Butter.png';
import CannedFood from './CannedFood.png';
import Cheese from './Cheese.png';
import Chicken from './Chicken.png';
import Coffee from './Coffee.png';
import CookingPowder from './CookingPowder.png';
import Cream from './Cream.png';
import Cutlery from './Cutlery.png';
import Detergent from './Detergent.png';
import DriedAgriculturalProduct from './DriedAgriculturalProduct.png';
import Egg from './Egg.png';
import Fish from './Fish.png';
import Flour from './Flour.png';
import FreshJuice from './FreshJuice.png';
import Fruit from './Fruit.png';
import Honey from './Honey.png';
import JapaneseSakeShochu from './JapaneseSake&Shochu.png';
import Kitchenware from './Kitchenware.png';
import Milk from './Milk.png';
import Mushroom from './Mushroom.png';
import Oil from './Oil.png';
import PastaNoodles from './Pasta&Noodles.png';
import Pork from './Pork.png';
import ProcessedSeafood from './ProcessedSeafood.png';
import ProcessedVegetables from './ProcessedVegetables.png';
import Rice from './Rice.png';
import Sauce from './Sauce.png';
import SausageHam from './Sausage&Ham.png';
import Seasoning from './Seasoning.png';
// import ShelClam from './Shel&Clam.png';
import Shrimp from './Shrimp.png';
import SoftDrink from './SoftDrink.png';
import Squid from './Squid.png';
import Syrup from './Syrup.png';
import Tea from './Tea.png';
import TissueTissueDispenser from './Tissue&TissueDispenser.png';
import Vegetable from './Vegetable.png';
import Vinegar from './Vinegar.png';
import Water from './Water.png';
import Yogurt from './Yogurt.png';
import DuckGoose from './DuckGoose.png';
import Lamb from './Lamb.png';
import PlantBased from './PlantBased.png';
import Crab from './Crab.png';
import Octopus from './Octopus.png';
import Puree from './Puree.png';
import IceCream from './IceCream.png';
import Bread from './Bread.png';
import GrainsNuts from './GrainsNuts.png';
import BakersConfectionery from './BakersConfectionery.png';
import Sugarconfectionery from './Sugarconfectionery.png';
import Jam from './Jam.png';
import Snack from './Snack.png';
import WhiteWine from './WhiteWine.png';
import RedWine from './RedWine.png';
import RoseWine from './RoseWine.png';
import SparklingWine from './SparklingWine.png';
import Liquor from './Liquor.png';
import Whisky from './Whisky.png';
import DraftBeer from './DraftBeer.png';
import Spirits from './Spirits.png';
import Dishes from './Dishes.png';
import GlassBottle from './GlassBottle.png';
// import Apparel from './Apparel.png';
import Laundry from './Laundry.png';
import TakeawayGoods from './TakeawayGoods.png';
import CleaningProduct from './CleaningProduct.png';
import TissuePaper from './TissuePaper.png';
import HairCare from './HairCare.png';
// import BodyWash from './BodyWash.png';
import OralHygiene from './OralHygiene.png';
import Sanitary from './Sanitary.png';
import OfficeSupplies from './OfficeSupplies.png';
import ShellClam from './Shell&Clam.png';


export default {
  1: Chicken,
  2: Pork,
  3: Beef,
  4: PlantBased,
  5: Egg,
  8: Crab,
  11: Squid,
  12: Octopus,
  13: ShellClam,
  14: ProcessedSeafood,
  16: Vegetable,
  17: Fruit,
  19: Milk,
  20: Cheese,
  22: Butter,
  23: IceCream,
  26: Rice,
  27: Flour,
  29: Oil,
  30: Sauce,
  31: Seasoning,
  33: Water,
  37: Tea,
  39: WhiteWine,
  40: RedWine,
  41: RoseWine,
  42: SparklingWine,
  46: Liquor,
  47: Whisky,
  52: Cutlery,
  54: TakeawayGoods,
  64: DuckGoose,
  65: Lamb,
  66: SausageHam,
  67: Fish,
  68: Shrimp,
  69: Mushroom,
  70: Puree,
  71: ProcessedVegetables,
  72: Cream,
  73: Yogurt,
  74: PastaNoodles,
  75: Bread,
  76: CannedFood,
  77: DriedAgriculturalProduct,
  78: GrainsNuts,
  80: BakersConfectionery,
  81: Sugarconfectionery,
  82: Jam,
  83: Snack,
  85: Vinegar,
  86: CookingPowder,
  87: Honey,
  88: Syrup,
  90: SoftDrink,
  91: FreshJuice,
  92: Coffee,
  94: BottleCanBeer,
  95: DraftBeer,
  96: Spirits,
  97: JapaneseSakeShochu,
  99: Dishes,
  100: GlassBottle,
  101: Kitchenware,
  102: Detergent,
  103: TissuePaper,
  104: OfficeSupplies,
  106: Laundry,
  107: CleaningProduct,
  108: TissuePaper,
  110: HairCare,
  112: OralHygiene,
  113: Sanitary,
};
